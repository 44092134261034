import * as PopoverPrimitives from "@radix-ui/react-popover";
import { MouseEvent, forwardRef, useState } from "react";
import { FaPopperOverlay } from "@/components/FaPopperOverlay/FaPopperOverlay";

type FaPopoverRootProps = PopoverPrimitives.PopoverProps & {
    onOverlayClick?: (e: MouseEvent<HTMLDivElement>) => void;
};

export const FaPopoverRoot = forwardRef<HTMLDivElement, FaPopoverRootProps>((props, forwardedRef) => {
    const { onOverlayClick, onOpenChange, open: controlledOpen, ...rootProps } = props;
    const [open, setOpen] = useState(false);

    return (
        <PopoverPrimitives.Root
            {...rootProps}
            open={controlledOpen ?? open}
            onOpenChange={value => {
                setOpen(value);
                onOpenChange?.(value);
            }}>
            <FaPopperOverlay ref={forwardedRef} open={controlledOpen ?? open} onClick={onOverlayClick} />
            {props.children}
        </PopoverPrimitives.Root>
    );
});
